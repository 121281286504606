import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import React, {useEffect, useState} from "react";
import Grid from "../../../../components/dialogs/Grid";
import SearchFab from "../../../../components/button/SearchFab";
import {ActivityFilterDto} from "../../../../common/apis/activity/ActivityFilterDto";
import DatePicker from "../../../../components/fields/date/DatePicker";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ActivityTranslationKey} from "./ActivityTranslationKey";
import MultiselectComboBox from "../../../../components/fields/MultiselectComboBox";
import activityService from "./ActivityService";
import {ActivityCategoryDto} from "../../../../common/apis/activity/ActivityCategoryDto";
import {endOfTheDay, startOfTheDay} from "../../../../common/DateUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
        formGridItem: {
            display: "flex",
            flexDirection: "column",
            "&> *": {
                flex: 1,
            }
        },
        searchActionGridItem: {
            display: 'flex',
            justifyContent: "flex-end",
        },
    })
);

export interface ActivitiesFilterProps {
    onFilterChange: (filter: ActivityFilterDto) => void;
}

export default function ActivitiesFilter({onFilterChange}: ActivitiesFilterProps) {

    const classes = useStyles();

    const [filter, setFilter] = useState<ActivityFilterDto>({validFrom: null, validThru: null});

    const [categories, setCategories] = useState<ActivityCategoryDto[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<ActivityCategoryDto[]>([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoriesData = await activityService().getCategories();
                setCategories(categoriesData);
            } catch (error) {
                console.error('Failed to fetch categories', error);
            }
        };

        fetchCategories();
    }, []);

    const handleCategoryChange = (changedValues: ActivityCategoryDto[]) => {
        const categoryIds = changedValues.map(category => category.id);
        setSelectedCategories(changedValues);
        setFilter({...filter, activityTypeCategories: categoryIds});
    };

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onFilterChange(filter);
    };

    return (
        <>
            <form onSubmit={(event: React.FormEvent) => onSubmit(event)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                        <DatePicker
                            value={filter.validFrom}
                            onChange={validFrom => setFilter({...filter, validFrom: startOfTheDay(validFrom || undefined) || null})}
                            label={_transl(ActivityTranslationKey.VALID_FROM)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                        <DatePicker
                            value={filter.validThru}
                            onChange={validThru => setFilter({...filter, validThru: endOfTheDay(validThru || undefined) || null})}
                            label={_transl(ActivityTranslationKey.VALID_THRU)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} className={classes.formGridItem}>
                        <MultiselectComboBox
                            label={_transl(ActivityTranslationKey.CATEGORIES)}
                            id="categories-multiselectcombobox"
                            options={categories}
                            selectedValues={selectedCategories}
                            getRenderLabel={(value) => _transl(value.langIdent)}
                            handleOnChange={handleCategoryChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm md lg className={classes.searchActionGridItem}>
                        <SearchFab inProgress={false}/>
                    </Grid>
                </Grid>
            </form>

        </>

    );
}
