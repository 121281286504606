import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React, {useState} from "react";
import ActivitiesGrid from "./ActivitiesGrid";
import {createActivityGridColDef} from "./createActivityGridColDef";
import {ActivityFilterDto} from "../../../../common/apis/activity/ActivityFilterDto";
import ActivitiesFilter from "./ActivitiesFilter";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ActivityTranslationKey} from "./ActivityTranslationKey";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
    })
);


export default function ActivitiesPage() {
    const classes = useStyles();
    const [filter, setFilter] = useState<ActivityFilterDto>({validFrom: null, validThru: null});

    return (
        <Paper className={classes.page}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {_transl(ActivityTranslationKey.ACTIVITY_TITLE)}
                </Typography>
            </div>
            <Divider/>
            <div className={classes.controlPageSegment}>
                <ActivitiesFilter onFilterChange={setFilter}/>
            </div>
            <Divider/>
            <div className={classes.controlPageSegment}>
                <ActivitiesGrid filter={filter} columns={createActivityGridColDef()}/>
            </div>
        </Paper>
    );
}
