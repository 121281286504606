import React, {useCallback, useContext, useEffect, useReducer, useState} from "react";
import {Badge, Tab, Tabs, TextField, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {IApplicationState} from "../../../../../store/Store";
import {TabContext, TabPanel} from "@mui/lab";
import {IPropertyDto} from "../../../../../common/apis/CommonTypes";
import Api from "../../../../../common/Api";
import EditableTextField from "../../../../../components/fields/EditableTextField";
import {AttachmentType} from "../../../../../store/common/Attachments";
import AttachmentsPanel from "../../../../../components/attachment/AttachmentsPanel";
import {PersistentStateId} from "../../../../../store/common/Grid";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../../components/grid/GridAction";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import SucceededAlert from "../../../../../components/SucceededAlert";
import NotSucceededAlert from "../../../../../components/NotSucceededAlert";
import Constants from "../../../../../common/Constants";
import {DiagramPropertyCreateDialog} from "../DiagramPropertyCreateDialog";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import UpdateDiagramPropertyDialog from "../UpdateDiagramPropertyDialog";
import {getFilterRefetchAction} from "../../../../../store/diagrams/Diagrams";
import EditableCollectionsComponent from "../../../../../components/fields/EditableCollectionsComponent";
import {getUiComponentsStateUpdateAction, UiComponentsStateType} from "../../../../../store/common/UiComponentsState";
import DiagramViewer from "../DiagramViewer";
import {DiagramDto} from "../../../../../common/apis/diagram/DiagramDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import {DiagramController} from "../controller/DiagramController";
import {DiagramDetailPanelGridButtonId} from "./DiagramDetailPanelGridButtonId";
import EditableViewpointComponent from "../EditableViewpointComponent";
import diagramService from "../service/DiagramService";
import {DiagramInfoDto} from "../../../../../common/apis/diagram/DiagramInfoDto";
import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {EditableLabelsComponent} from "../../../../../components/fields/label/EditableLabelsComponent";
import {useStyles} from "./styles";
import {FetchStatus, LeftPanelTabId, RightPanelTabId} from "./enums";
import {createDiagramDetailPanelGridColDef} from "./DiagramDetailPanelColumnDefinition";
import Grid from "../../../../../components/dialogs/Grid";
import chatService, {ChatDto} from "../../../../../common/apis/chat/ChatService";
import Snackbar from "../../snackbar/Snackbar";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import ElementDetailDialog from "../../elementdetail/ElementDetailDialog";
import ChatBox from "../../chat/detail/ChatBox";
import {TABS_HEIGHT} from "../../elementdetail/MainPanel";
import DiagramDetailHeader from "./DiagramDetailHeader";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";
import DiagramPropertyDefinitionContext, {
    DiagramPropertyDefinition
} from "../../../../../common/DiagramPropertyDefinitionContext";
import ActivitiesGrid from "../../activity/ActivitiesGrid";
import {createActivityDiagramsGridColDef} from "../../activity/createActivityDiagramsGridColDef";
import {ActivityTranslationKey} from "../../activity/ActivityTranslationKey";


interface IState {
    diagram: {
        fetchStatus: FetchStatus,
        isSilentFetch: boolean,
        data?: DiagramDto,
    },
    diagramProperties?: Array<IPropertyDto>,
    alertSucceeded?: {
        text: string
    },
    alertNotSucceeded?: {
        text: string,
    },
    propertyAddDialogOpened?: boolean,
    propertyDeleteDialog?: {
        selectedRowId: string,
    },
    propertyChangeDialog?: {
        selectedProperty: IPropertyDto,
    }
}

const initialState: IState = {
    diagram: {
        fetchStatus: FetchStatus.IN_PROGRESS,
        isSilentFetch: false,
    },
}

enum ActionType {
    UPDATE_DIAGRAM = "UPDATE_DIAGRAM",
    UPDATE_DIAGRAM_PROPERTIES = "UPDATE_DIAGRAM_PROPERTIES",
    UPDATE_ALERT_SUCCEEDED = "UPDATE_ALERT_SUCCEEDED",
    UPDATE_ALERT_NOT_SUCCEEDED = "UPDATE_ALERT_NOT_SUCCEEDED",
    UPDATE_PROPERTY_DELETE_DIALOG = "UPDATE_PROPERTY_DELETE_DIALOG",
    UPDATE_PROPERTY_ADD_DIALOG = "UPDATE_PROPERTY_ADD_DIALOG",
    UPDATE_PROPERTY_CHANGE_DIALOG = "UPDATE_PROPERTY_CHANGE_DIALOG",
    HIDE_ALL_DIALOGS = "HIDE_ALL_DIALOGS",
}

type Action =
    { type: ActionType.UPDATE_DIAGRAM, payload: { fetchStatus: FetchStatus, isSilentFetch: boolean, data: DiagramDto | undefined } }
    |
    { type: ActionType.UPDATE_DIAGRAM_PROPERTIES, payload: Array<IPropertyDto> }
    |
    { type: ActionType.UPDATE_ALERT_SUCCEEDED, payload: { text: string } | undefined }
    |
    { type: ActionType.UPDATE_ALERT_NOT_SUCCEEDED, payload: { text: string } | undefined }
    |
    { type: ActionType.UPDATE_PROPERTY_DELETE_DIALOG, payload: { selectedRowId: string } | undefined }
    |
    { type: ActionType.UPDATE_PROPERTY_ADD_DIALOG, payload: boolean }
    |
    { type: ActionType.UPDATE_PROPERTY_CHANGE_DIALOG, payload: { selectedProperty: IPropertyDto } | undefined }
    |
    { type: ActionType.HIDE_ALL_DIALOGS };

function getUpdatingDiagramAction(isSilentFetch: boolean): Action {
    return {
        type: ActionType.UPDATE_DIAGRAM,
        payload: {fetchStatus: FetchStatus.IN_PROGRESS, isSilentFetch: isSilentFetch, data: undefined},
    }
}

function getDiagramUpdatedAction(fetchStatus: FetchStatus.SUCCEEDED | FetchStatus.FAILED, diagram?: DiagramDto): Action {
    return {
        type: ActionType.UPDATE_DIAGRAM,
        payload: {fetchStatus: fetchStatus, isSilentFetch: false, data: diagram},
    }
}

function getPropertiesUpdatedAction(properties: IPropertyDto[]): Action {
    return {
        type: ActionType.UPDATE_DIAGRAM_PROPERTIES,
        payload: properties,
    };
}

function getShowSucceededAlertAction(text: string): Action {
    return {
        type: ActionType.UPDATE_ALERT_SUCCEEDED,
        payload: {text: text},
    }
}

function getShowNotSucceededAlertAction(text: string): Action {
    return {
        type: ActionType.UPDATE_ALERT_NOT_SUCCEEDED,
        payload: {text: text},
    }
}

function getShowDeletePropertyDialogAction(selectedRowId: string): Action {
    return {
        type: ActionType.UPDATE_PROPERTY_DELETE_DIALOG,
        payload: {selectedRowId: selectedRowId},
    }
}

function getShowAddPropertyDialogAction(): Action {
    return {
        type: ActionType.UPDATE_PROPERTY_ADD_DIALOG,
        payload: true,
    }
}

function getShowChangePropertyDialogAction(selectedProperty: IPropertyDto): Action {
    return {
        type: ActionType.UPDATE_PROPERTY_CHANGE_DIALOG,
        payload: {selectedProperty: selectedProperty},
    }
}

function getHideAllDialogsAction(): Action {
    return {
        type: ActionType.HIDE_ALL_DIALOGS
    }
}

function reducer(state: IState, action: Action): IState {
    switch (action.type) {
        case ActionType.UPDATE_DIAGRAM:
            const isInProgress = action.payload.fetchStatus === FetchStatus.IN_PROGRESS;
            let diagram = {...state.diagram};
            if (isInProgress) {
                diagram.isSilentFetch = action.payload.isSilentFetch;
                if (!action.payload.isSilentFetch) {
                    diagram.data = undefined;
                }
            } else {
                diagram = action.payload;
            }
            return {
                ...state,
                diagram: diagram,
            };
        case ActionType.UPDATE_DIAGRAM_PROPERTIES:
            return {
                ...state,
                diagramProperties: action.payload,
            };
        case ActionType.UPDATE_ALERT_SUCCEEDED:
            return {
                ...state,
                alertSucceeded: action.payload,
            };
        case ActionType.UPDATE_ALERT_NOT_SUCCEEDED:
            return {
                ...state,
                alertNotSucceeded: action.payload,
            };
        case ActionType.UPDATE_PROPERTY_DELETE_DIALOG:
            return {
                ...state,
                propertyDeleteDialog: action.payload,
            };
        case ActionType.UPDATE_PROPERTY_ADD_DIALOG:
            return {
                ...state,
                propertyAddDialogOpened: action.payload,
            }
        case ActionType.UPDATE_PROPERTY_CHANGE_DIALOG:
            return {
                ...state,
                propertyChangeDialog: action.payload,
            }
        case ActionType.HIDE_ALL_DIALOGS:
            return {
                ...state,
                propertyChangeDialog: undefined,
                propertyDeleteDialog: undefined,
                propertyAddDialogOpened: false,
                alertSucceeded: undefined,
                alertNotSucceeded: undefined,
            }
        default:
            return state;
    }
}

interface DiagramDetailPanelProps {
    id: string;
    controller: DiagramController;
    diagramInfoDto?: DiagramInfoDto;
    hideDiagramPreviewTab?: boolean;
    hideShowAndEditIcons?: boolean;
    showHeader? : boolean;
    onDiagramUpdated?: (id: string) => void;
}

function fetchDiagramById(diagramId: string, dispatch: React.Dispatch<Action>, isSilent: boolean) {
    dispatch(getUpdatingDiagramAction(isSilent));
    Api.diagrams.getDiagramById(diagramId).subscribe({
        next: (response) => dispatch(getDiagramUpdatedAction(FetchStatus.SUCCEEDED, response.response as DiagramDto)),
        error: () => dispatch(getDiagramUpdatedAction(FetchStatus.FAILED)),
    })
}

function fetchDiagramProperties(diagramId: string, dispatch: React.Dispatch<Action>) {
    diagramService.getAllDiagramProperties(diagramId)
        .then(properties => dispatch(getPropertiesUpdatedAction(properties)));
}

function refetchFilter(reduxDispatch: any) {
    reduxDispatch(getFilterRefetchAction());
}

function onPropertyUpdate(diagramId: string,
                          dispatch: React.Dispatch<Action>,
                          reduxDispatch: any) {
    fetchDiagramById(diagramId, dispatch, true);
    refetchFilter(reduxDispatch);
}

export default function DiagramDetailPanel({id, controller, hideDiagramPreviewTab, hideShowAndEditIcons, showHeader, onDiagramUpdated, diagramInfoDto}: DiagramDetailPanelProps) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [{
        diagram, diagramProperties, alertSucceeded, alertNotSucceeded,
        propertyAddDialogOpened, propertyDeleteDialog, propertyChangeDialog
    }, dispatch
    ] = useReducer(reducer, initialState);

    const hideDiagram = hideDiagramPreviewTab ? hideDiagramPreviewTab : false;


    const reduxDispatch = useDispatch();
    const selectedLeftPanelTab = useSelector((state: IApplicationState) => state.uiComponentsState.DIAGRAM_DETAIL_LEFTPANEL_SELECTED_TAB as LeftPanelTabId || LeftPanelTabId.DETAIL);
    const selectedRightPanelTab = useSelector((state: IApplicationState) => hideDiagramPreviewTab ? RightPanelTabId.ATTRIBUTES : (state.uiComponentsState.DIAGRAM_DETAIL_RIGHTPANEL_SELECTED_TAB as RightPanelTabId || RightPanelTabId.PREVIEW));
    const labels = useSelector((state: IApplicationState) => state.pages.common.options.labels.resource);

    const [chats, setChats] = useState<ChatDto[]>([]);
    const [elementDetailDialogInfo, setElementDetailDialogInfo] = useState<string | undefined>();
    const diagramPropertyDefinitionContext = useContext<DiagramPropertyDefinition>(DiagramPropertyDefinitionContext);

    useEffect(() => {
        fetchDiagramById(id, dispatch, false);
        fetchDiagramProperties(id, dispatch);
    }, [id]);

    const fetchChats = useCallback(() => {
        chatService.searchChatsByFilter({diagramId: id})
            .then(chats => {
                setChats(chats);
            })
            .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA)));
    }, [id]);

    useEffect(() => {
        fetchChats();
    }, [fetchChats]);

    function onChatCommentEdited(chatId: number, commentId: number, message: string) {
        const copiedChats = [...chats];
        const chat = copiedChats.find((chat) => chat.id === chatId);
        if (chat) {
            const comment = chat.chatComments.find(comment => comment.id === commentId);
            if (comment) {
                comment.message = message;
            }
            setChats(copiedChats);
        }
        fetchChats();
    }

    const diagramFetchStatus = diagram.fetchStatus;
    const isDiagramSilentFetch = diagram.isSilentFetch;
    const diagramInfo = diagram.data?.diagramInfo;
    const diagramAcl = diagramInfo?.acl;
    const isReadOnly = controller.isDiagramReadOnly(diagramAcl);
    const [attachmentsCount, setAttachmentsCount] = useState<number>(diagramInfoDto?.attachmentsCount || 0);

    function updateSelectedLeftPanelTab(tabId: LeftPanelTabId) {
        reduxDispatch(getUiComponentsStateUpdateAction(UiComponentsStateType.DIAGRAM_DETAIL_LEFTPANEL_SELECTED_TAB, tabId));
    }

    function updateSelectedRightPanelTab(tabId: RightPanelTabId) {
        reduxDispatch(getUiComponentsStateUpdateAction(UiComponentsStateType.DIAGRAM_DETAIL_RIGHTPANEL_SELECTED_TAB, tabId));
    }

    function deleteRow(propertyId: string) {
        dispatch(getHideAllDialogsAction());
        diagramService.deleteDiagramProperty(id, propertyId)
            .then((response) => {
                dispatch(getShowSucceededAlertAction(_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_DELETE_SUCCESS)));
                setTimeout(() => dispatch(getHideAllDialogsAction()), Constants.FE_APP_ALERT_DELAY);
                diagramPropertyDefinitionContext.updatePropertyDefinitions();
                fetchDiagramProperties(id, dispatch);
            })
            .catch((err) => {
                dispatch(getShowNotSucceededAlertAction(_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_DELETE_FAILURE)));
                setTimeout(() => dispatch(getHideAllDialogsAction()), Constants.FE_APP_ALERT_DELAY);
            });
    }

    const properties = diagramProperties ? diagramProperties : [];
    properties.sort((a, b) => {
        const nameA = a.definition.name.toLowerCase();
        const nameB = b.definition.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    const rows = properties.map((prop, index) => ({
        identifier: prop.definition.identifier,
        name: prop.definition.name,
        type: prop.definition.type,
        value: prop.value,
        index: index,
    }));

    return (
        <Grid container className={classes.fullHeight}>
            {((diagramFetchStatus === FetchStatus.SUCCEEDED && diagramInfo) ||
                    (diagramFetchStatus === FetchStatus.IN_PROGRESS && isDiagramSilentFetch && diagramInfo)) &&
                <Grid container className={classes.fullHeight}>
                    {showHeader &&
                        <Grid item xs={12}>
                            <DiagramDetailHeader
                                diagram={diagramInfo}
                                hideShowAndEditDiagramIcons={hideShowAndEditIcons}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={6} className={classes.fullHeight}>
                        <TabContext value={selectedLeftPanelTab}>
                            <Tabs
                                value={selectedLeftPanelTab}
                                onChange={(e, val) => updateSelectedLeftPanelTab(val)}
                                indicatorColor="primary"
                                textColor="primary"
                                style={{height: TABS_HEIGHT}}
                            >
                                <Tab value={LeftPanelTabId.DETAIL} className={classes.tabs}
                                     label={<span>{_transl(DiagramTranslationKey.DETAIL_TAB_DETAIL)}</span>}/>
                                <Tab
                                    className={classes.tabs}
                                    label={
                                        <Badge badgeContent={`${chats.length}`}
                                               color={"secondary"}>
                                            {_transl(DiagramTranslationKey.DETAIL_TAB_COMMENTS)}
                                        </Badge>
                                    }
                                    value={LeftPanelTabId.CHATS}
                                />
                                <Tab
                                    className={classes.tabs}
                                    label={
                                        <Badge badgeContent={`${attachmentsCount}`}
                                               color={"secondary"}>
                                            {_transl(DiagramTranslationKey.DETAIL_TAB_ATTACHMENTS)}
                                        </Badge>
                                    }
                                    value={LeftPanelTabId.ATTACHMENTS}
                                />
                            </Tabs>
                            <TabPanel value={LeftPanelTabId.DETAIL} className={classes.tabPanel}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <EditableTextField id={_transl(DiagramTranslationKey.DETAIL_NAME)}
                                                           label={_transl(DiagramTranslationKey.DETAIL_NAME)}
                                                           initialValue={diagramInfo.name || ""}
                                                           doUpdate={(text) => Api.diagrams.updateNameById(id, text).toPromise()}
                                                           onSuccessfulUpdate={(text) => {
                                                                    onPropertyUpdate(id, dispatch, reduxDispatch);
                                                                    onDiagramUpdated?.(id);
                                                                }
                                                           }
                                                           readonly={isReadOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="item-detail-basic-panel-id"
                                            label={_transl(DiagramTranslationKey.DETAIL_IDENTIFIER)}
                                            defaultValue={id || " "}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                            size={"small"}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <EditableViewpointComponent
                                            id="item-detail-basic-panel-viewpoint"
                                            label={_transl(DiagramTranslationKey.DETAIL_VIEWPOINT)}
                                            assignedViewpoint={diagramInfo.viewpoint || " "}
                                            doUpdate={(text) => Api.diagrams.updateViewpointById(id, text).toPromise()}
                                            onSuccessfulUpdate={(text) => {
                                                onPropertyUpdate(id, dispatch, reduxDispatch);
                                                onDiagramUpdated?.(id);
                                            }}
                                            readonly={isReadOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EditableTextField id={"diagram-detail-item-description"}
                                                           label={_transl(DiagramTranslationKey.DETAIL_DESCRIPTION)}
                                                           rows={8}
                                                           initialValue={diagramInfo.description || ""}
                                                           doUpdate={(text) => Api.diagrams.updateDescriptionById(id, text).toPromise()}
                                                           onSuccessfulUpdate={(text) => onPropertyUpdate(id, dispatch, reduxDispatch)}
                                                           readonly={isReadOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EditableLabelsComponent textFieldId={"diagram-detail-basic-panel-labels"}
                                                                 publicLabelsAssigningPermitted={diagramInfo.acl.canUpdatePublicLabels}
                                                                 allLabels={labels}
                                                                 initiallyAssignedLabels={diagramInfo.labels}
                                                                 doUpdate={(labels: ILabelDto[]) => Api.diagrams.updateLabels(id, labels).toPromise()}
                                                                 onSuccessfulUpdate={(labels: ILabelDto[]) => {
                                                                     onPropertyUpdate(id, dispatch, reduxDispatch);
                                                                     onDiagramUpdated?.(id);
                                                                 }}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EditableCollectionsComponent
                                            assignedCollectionCodes={diagramInfo.collections.map(label => label.code)}
                                            doUpdate={(items) => Api.diagrams.updateCollections(id, items).toPromise()}
                                            onSuccessfulUpdate={(collections) => {
                                                onPropertyUpdate(id, dispatch, reduxDispatch);
                                                onDiagramUpdated?.(id);
                                            }}
                                            readonly={!diagramAcl?.canUpdateCollection}/>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={LeftPanelTabId.CHATS} className={classes.tabPanel}
                                      style={{height: `calc(100% - ${TABS_HEIGHT})`}}>
                                <ChatBox chats={chats}
                                         canCreateChats={diagram.data?.diagramInfo.acl.canCreateChats ?? false}
                                         onChatsUpdated={fetchChats}
                                         onChatCommentEdited={onChatCommentEdited}
                                         openElementDetail={(id: string) => setElementDetailDialogInfo(id)}
                                         diagramId={id}/>
                                {elementDetailDialogInfo &&
                                    <ElementDetailDialog initialElementId={elementDetailDialogInfo}
                                                         opened={true}
                                                         onClosed={() => setElementDetailDialogInfo(undefined)}/>
                                }
                            </TabPanel>
                            <TabPanel value={LeftPanelTabId.ATTACHMENTS} className={classes.tabPanel}>
                                <AttachmentsPanel resourceType={AttachmentType.DIAGRAMS}
                                                  resourceId={id}
                                                  canUploadAttachment={diagramAcl?.canCreateAttachment}
                                                  onAttachmentsUpdated={(attachmentCount: number) => setAttachmentsCount(attachmentCount)}/>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.fullHeight}>
                        <TabContext value={selectedRightPanelTab}>
                            <Tabs
                                value={selectedRightPanelTab}
                                onChange={(e, val) => updateSelectedRightPanelTab(val)}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {!hideDiagram && <Tab className={classes.tabs}
                                                      value={RightPanelTabId.PREVIEW}
                                                      label={
                                                          <span>{_transl(DiagramTranslationKey.DETAIL_TAB_PREVIEW)}</span>}/>
                                }

                                <Tab
                                    className={classes.tabs}
                                    value={RightPanelTabId.ATTRIBUTES}
                                    label={<span>{_transl(DiagramTranslationKey.DETAIL_TAB_ATTRIBUTES)}</span>}
                                />
                                <Tab
                                    className={classes.tabs}
                                    value={RightPanelTabId.ACTIVITIES}
                                    label={<span>{_transl(ActivityTranslationKey.ACTIVITY_TITLE)}</span>}
                                />
                            </Tabs>
                            {alertSucceeded &&
                                <SucceededAlert text={alertSucceeded.text}
                                                opened={true}
                                                onClose={() => dispatch(getHideAllDialogsAction())}
                                />
                            }
                            {alertNotSucceeded &&
                                <NotSucceededAlert text={alertNotSucceeded.text}
                                                   opened={true}
                                                   onClose={() => dispatch(getHideAllDialogsAction())}
                                />
                            }
                            {propertyDeleteDialog &&
                                <ConfirmationDialog open={true}
                                                    title={_transl(DiagramTranslationKey.DETAIL_DIALOG_DELETE_ATTRIBUTE_TITLE)}
                                                    confirmationText={_transl(DiagramTranslationKey.DETAIL_DIALOG_DELETE_ATTRIBUTE_TEXT)}
                                                    onConfirm={() => {
                                                        deleteRow(propertyDeleteDialog.selectedRowId);
                                                    }}
                                                    onReject={() => dispatch(getHideAllDialogsAction())}
                                />
                            }
                            {!hideDiagram && <TabPanel value={RightPanelTabId.PREVIEW} className={classes.tabPanel}>
                                <div className={classes.preview}>
                                    <div className={classes.controlPageSegment}>
                                        <DiagramViewer diagramId={id}/>
                                    </div>
                                </div>
                            </TabPanel>}
                            <TabPanel value={RightPanelTabId.ATTRIBUTES} className={classes.tabPanel}>
                                {propertyAddDialogOpened &&
                                    <DiagramPropertyCreateDialog
                                        open={true}
                                        existingPropertyNames={new Set(properties.map(value => value.definition.name))}
                                        onClosed={() => dispatch(getHideAllDialogsAction())}
                                        diagramId={id}
                                        onPropertyCreated={() => fetchDiagramProperties(id, dispatch)}
                                    />
                                }
                                {propertyChangeDialog &&
                                    <UpdateDiagramPropertyDialog
                                        opened={true}
                                        onClosed={() => dispatch(getHideAllDialogsAction())}
                                        diagramId={id}
                                        onPropertyChanged={() => {
                                            fetchDiagramProperties(id, dispatch);
                                        }}
                                        selectedProperty={propertyChangeDialog.selectedProperty}
                                    />
                                }
                                <ExtGridWrapper
                                    columns={createDiagramDetailPanelGridColDef(_transl)}
                                    rows={rows}
                                    rowCount={rows.length}
                                    getRowId={row => row.identifier + row.index}
                                    actions={[
                                        GridAction.buttonBuilder(DiagramDetailPanelGridButtonId.DIAGRAM_ADD_ATTRIBUTE_BUTTON, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.DETAIL_ADD_ATTRIBUTE),
                                            <AddIcon/>)
                                            .enabledPolicy(EnabledPolicy.ALWAYS)
                                            .isEnabled(() => diagram.data ? diagram.data.diagramInfo.acl.canCreateAttachment : false)
                                            .onClick(() => dispatch(getShowAddPropertyDialogAction()))
                                            .build(),
                                        GridAction.buttonBuilder(DiagramDetailPanelGridButtonId.DIAGRAM_UPDATE_ATTRIBUTE_BUTTON, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.DETAIL_UPDATE_ATTRIBUTE),
                                            <CreateIcon/>)
                                            .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                                            .isEnabled(() => diagram.data ? diagram.data.diagramInfo.acl.canUpdate : false)
                                            .onClick((selectedRowIds, selectedRows) => {
                                                const item = diagramProperties?.filter(property => property.definition.identifier === selectedRows[0].identifier)[0];
                                                dispatch(getShowChangePropertyDialogAction(item as IPropertyDto));
                                            }).build(),
                                        GridAction.buttonBuilder(DiagramDetailPanelGridButtonId.DIAGRAM_DELETE_ATTRIBUTE_BUTTON, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.DETAIL_REMOVE_ATTRIBUTE),
                                            <DeleteIcon/>)
                                            .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                                            .isEnabled(() => diagram.data ? diagram.data.diagramInfo.acl.canUpdate : false)
                                            .onClick((selectedRowIds, selectedRows) => {
                                                dispatch(getShowDeletePropertyDialogAction(selectedRows[0].identifier));
                                            }).build()
                                    ]}
                                    peristentStateId={PersistentStateId.DIAGRAM_DETAIL_PAGE_ATTRIBUTES_GRID}
                                    resourceId={id || ""}
                                />
                            </TabPanel>
                            <TabPanel value={RightPanelTabId.ACTIVITIES} className={classes.tabPanel}>
                                <ActivitiesGrid columns={createActivityDiagramsGridColDef()} filter={{entityIdent: id, validFrom: null, validThru: null}}/>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
            }
            {diagramFetchStatus === FetchStatus.FAILED &&
                <div>
                    {_transl(DiagramTranslationKey.DETAIL_LOADING_FAILED)}
                </div>
            }
            {diagramFetchStatus === FetchStatus.IN_PROGRESS && !isDiagramSilentFetch &&
                <div>
                    {_transl(DiagramTranslationKey.DETAIL_LOADING_IN_PROCESS)}
                </div>
            }
        </Grid>
    );

}
