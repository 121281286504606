import React, {useContext} from "react";
import {Menu} from "@mui/material";
import {
    activitiesMenu,
    exportMenu,
    generateMetamodelMenu,
    importMenu,
    modelDocumentationMenu,
    modelsComparisonMenu,
    queriesMenu,
    validateModelMenu
} from "../../../common/menudefinition/MenuDefinitionUtils";
import Divider from "@mui/material/Divider";
import ConfigurationContext from "../../../common/ConfigurationContext";
import importPageAccessResolver from "../content/import/ImportPageAccessResolver";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../store/Store";
import AppBarMenuItem from "./AppBarMenuItem";
import {UserRole} from "../../../common/access/UserRole";
import {UserRoleType} from "../../../common/access/UserRoleType";

export interface ToolsMenuProps {
    opened: boolean;
    anchorRef: any;
    onMenuClose: () => void;
}

export default function ToolsMenu({opened, anchorRef, onMenuClose}: ToolsMenuProps) {
    const {featureStatuses} = useContext(ConfigurationContext);

    const user = useSelector((state: IApplicationState) => state.user.userData);
    const importPageVisible = importPageAccessResolver.hasPageAccess(user);
    const activitiesPageVisible = user != null ? UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE) : false;

    return (
        <Menu open={opened}
              id="administration-menu"
              anchorEl={anchorRef}
              keepMounted
              onClose={onMenuClose}
              transformOrigin={{vertical: -10, horizontal: 0}} >
            {featureStatuses.graphQueryEnabled &&
                <AppBarMenuItem menuDefinition={queriesMenu} onClick={onMenuClose} />
            }
            {featureStatuses.graphQueryEnabled &&
                <Divider />
            }
            <AppBarMenuItem menuDefinition={exportMenu} onClick={onMenuClose} />
            {importPageVisible &&
                <AppBarMenuItem menuDefinition={importMenu} onClick={onMenuClose} />
            }
            <Divider />
            <AppBarMenuItem menuDefinition={modelDocumentationMenu} onClick={onMenuClose} />
            <Divider />
            <AppBarMenuItem menuDefinition={modelsComparisonMenu} onClick={onMenuClose} />
            <AppBarMenuItem menuDefinition={generateMetamodelMenu} onClick={onMenuClose} />
            <AppBarMenuItem menuDefinition={validateModelMenu} onClick={onMenuClose} />
            <Divider />
            {activitiesPageVisible &&
                <AppBarMenuItem menuDefinition={activitiesMenu} onClick={onMenuClose}/>
            }
        </Menu>
    )

}
