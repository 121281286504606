import {
    activitiesPage,
    administrationPage,
    appInfoPage,
    collectionsPage,
    dashboardAdministrationPage,
    dashboardPage,
    diagramsPage,
    elementsPage,
    exportPage,
    generateMetamodelPage,
    importPage,
    labelsPage,
    logoutPage,
    modelDocumentationPage,
    modelsComparisonPage,
    profilePage,
    queriesPage,
    stereotypesPage,
    structuresPage,
    toolsPage,
    usersPage,
    validateModelPage
} from "../routes/AppRoutes";
import MenuDefinition from "./MenuDefinition";
import PublicIcon from "@mui/icons-material/Public";
import React from "react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoIcon from '@mui/icons-material/Info';
import {UsersTranslationKey} from "../../pages/main/content/users/UsersTranslationKey";
import {LabelsTranslationKey} from "../../pages/main/content/labels/LabelsTranslationKey";
import {CollectionsTranslationKey} from "../../pages/main/content/collections/CollectionsTranslationKey";
import {AppBarTranslationKey} from "../../pages/main/appbar/AppBarTranslationKey";
import {StereotypesTranslationKey} from "../../pages/main/content/stereotypes/StereotypesTranslationKey";
import {DashboardTranslationKey} from "../../pages/main/content/dashboard/DashboardTranslationKey";
import {MenuDefinitionTranslationKey} from "./MenuDefinitionTranslationKey";
import {
    GenerateMetamodelTranslationKey
} from "../../pages/main/content/metamodel/extraction/GenerateMetamodelTranslationKey";
import {
    AccountCircle,
    AppsRounded,
    Category,
    Compare,
    GetApp,
    Home,
    Lan,
    LocalActivity,
    LocalOffer,
    Person,
    PictureAsPdf,
    Publish,
    QueryStats,
    Rule,
    Settings,
    Shield
} from "@mui/icons-material";
import {ModelsComparisonTranslationKey} from "../../pages/main/content/modelscomparison/ModelsComparisonTranslationKey";
import {GraphQueryTranslationKey} from "../../pages/main/content/graphquery/GraphQueryTranslationKey";
import {ValidateModelTranslationKey} from "../../pages/main/content/metamodel/validation/ValidateModelTranslationKey";
import {
    ModelDocumentationTranslationKey
} from "../../pages/main/content/documentation/ModelDocumentationTranslationKey";
import {ActivityTranslationKey} from "../../pages/main/content/activity/ActivityTranslationKey";

export const dashboardMenu = new MenuDefinition(DashboardTranslationKey.NAME, <HomeIcon/>, () => dashboardPage);
export const structuresMenu = new MenuDefinition(MenuDefinitionTranslationKey.STRUCTURES, <PublicIcon />, () => structuresPage);
export const elementsMenu = new MenuDefinition(MenuDefinitionTranslationKey.ELEMENTS, <AppsRounded/>, () => elementsPage);
export const diagramsMenu = new MenuDefinition(MenuDefinitionTranslationKey.DIAGRAMS, <AccountTreeIcon/>, () => diagramsPage);
export const toolsMenu = new MenuDefinition(MenuDefinitionTranslationKey.TOOLS, <BuildIcon/>, () => toolsPage);
export const administrationMenu = new MenuDefinition(MenuDefinitionTranslationKey.ADMINISTRATION, <Settings/>, () => administrationPage);

export const queriesMenu = new MenuDefinition(GraphQueryTranslationKey.QUERIES, <QueryStats/>, () => queriesPage);
export const exportMenu = new MenuDefinition("Export", <Publish/>, () => exportPage);
export const importMenu = new MenuDefinition("Import", <GetApp/>, () => importPage);
export const generateMetamodelMenu = new MenuDefinition(GenerateMetamodelTranslationKey.GENERATED_METAMODEL, <Lan/>, () => generateMetamodelPage);
export const modelsComparisonMenu = new MenuDefinition(ModelsComparisonTranslationKey.PAGE_TITLE, <Compare/>, () => modelsComparisonPage);
export const validateModelMenu = new MenuDefinition(ValidateModelTranslationKey.VALIDATE_AGAINST_METAMODEL, <Rule/>, () => validateModelPage);
export const activitiesMenu = new MenuDefinition(ActivityTranslationKey.ACTIVITY_TITLE, <LocalActivity/>, () => activitiesPage);
export const modelDocumentationMenu = new MenuDefinition(ModelDocumentationTranslationKey.TITLE, <PictureAsPdf />, () => modelDocumentationPage);
export const usersMenu = new MenuDefinition(UsersTranslationKey.TITLE, <Person/>, () => usersPage);
export const labelsMenu = new MenuDefinition(LabelsTranslationKey.TITLE, <LocalOffer/>, () => labelsPage);
export const collectionsMenu = new MenuDefinition(CollectionsTranslationKey.TITLE, <Shield/>, () => collectionsPage);
export const stereotypesMenu = new MenuDefinition(StereotypesTranslationKey.TITLE, <Category/>, () => stereotypesPage);
export const dashboardAdministrationMenu = new MenuDefinition(DashboardTranslationKey.NAME, <Home/>, () => dashboardAdministrationPage);

export const profileMenu = new MenuDefinition(AppBarTranslationKey.PROFILE, <AccountCircle/>, () => profilePage);
export const appInfoMenu = new MenuDefinition(AppBarTranslationKey.APP_INFO, <InfoIcon/>, () => appInfoPage);
export const logoutMenu = new MenuDefinition(AppBarTranslationKey.LOGOUT, <ExitToAppIcon/>, () => logoutPage)
