import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import moment from "moment";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ActivityTranslationKey} from "./ActivityTranslationKey";

const dateFormatter = (params: GridValueFormatterParams) => params.value != null ? moment(params.value as Date).format("DD.MM.YYYY") : "";

const getTargetObjectValue = (targetObjectId: any, targetObjectName: any) => {
    if (!targetObjectId) return "";
    if (!targetObjectName) return `${targetObjectId}`;
    return `${targetObjectId} (${targetObjectName})`;
};

export function createActivityGridColDef(): GridColDef[] {
    return [
        {
            field: 'userCreated',
            headerName: _transl(ActivityTranslationKey.USER_CREATED),
            width: 150,
            sortable: false
        },
        {
            field: 'created',
            headerName: _transl(ActivityTranslationKey.CREATED),
            width: 150,
            valueFormatter: dateFormatter
        },
        {
            field: 'objectId',
            headerName: _transl(ActivityTranslationKey.OBJECT_IDENTIFIER),
            width: 300,
            sortable: false
        },
        {
            field: 'objectName',
            headerName: _transl(ActivityTranslationKey.OBJECT_NAME),
            width: 300,
            sortable: false
        },
        {
            field: 'targetObject',
            headerName: _transl(ActivityTranslationKey.TARGET_OBJECT),
            width: 300,
            sortable: false,
            valueGetter: (params) => getTargetObjectValue(params.row.targetObjectId, params.row.targetObjectName)
        }
    ]
}
