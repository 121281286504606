export enum ActivityTranslationKey {
    ACTIVITY_TITLE = "activity.title",
    USER_CREATED = "activity.userCreated",
    CREATED = "activity.created",
    OBJECT_IDENTIFIER = "activity.objectIdentifier",
    OBJECT_NAME = "activity.objectName",
    TARGET_OBJECT = "activity.targetObject",
    TYPE = "activity.type",
    VALID_FROM = "activity.validFrom",
    VALID_THRU = "activity.validThru",
    CATEGORIES = "activity.categories",
}